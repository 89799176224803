<template>
  <div class="pagination">
    <v-btn
      class="pagination-button"
      icon
      small
      @click="onPreviousPage"
      :disabled="isPrevButtonDisabled"
      aria-label="prev"
      ><v-icon> mdi-arrow-left </v-icon></v-btn
    >
    <div class="current-page">{{ paginationMessage }}</div>
    <v-btn
      class="pagination-button"
      icon
      small
      @click="onNextPage"
      :disabled="isNextButtonDisabled"
      aria-label="next"
      ><v-icon> mdi-arrow-right </v-icon></v-btn
    >
  </div>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    content: String,
    onNext: Function,
    onPrevious: Function,
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    isPrevButtonDisabled() {
      return this.currentPage <= 1;
    },
    isNextButtonDisabled() {
      return this.currentPage >= this.totalPages;
    },
    paginationMessage() {
      if (this.content) {
        return this.content;
      }
      return `Page ${this.currentPage} of ${this.totalPages}`;
    },
  },
  methods: {
    onNextPage(e) {
      if (this.currentPage < this.totalPages) {
        this.$emit("update", this.currentPage + 1);
        this.onNext && this.onNext(e);
      }
    },
    onPreviousPage(e) {
      if (this.currentPage > 1) {
        this.$emit("update", this.currentPage - 1);
        this.onPrevious && this.onPrevious(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.current-page {
  color: #6a707e;
  font-weight: 700;
  font-size: 14px;
  padding: 0 48px;
}
.pagination-button {
  box-shadow: 0px 6px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%) !important;
  background-color: white !important;
  outline: none;
  .v-icon {
    color: $inqliPurple;
  }
  .v-icon:hover {
    color: $inqliButtonHover;
  }
  .v-icon:focus {
    color: $inqliButtonFocus;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .current-page {
    padding: 0 16px;
  }
}
</style>
