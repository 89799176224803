var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-data-table", {
    ref: "vDataTable",
    staticClass: "elevation-1 data-table",
    attrs: {
      headers: _vm.extendedHeaders,
      items: _vm.items,
      expanded: _vm.expanded,
      "item-key": "id",
      "show-expand": "",
      "item-class": _vm.getItemClass,
      "hide-default-footer": ""
    },
    on: {
      "update:expanded": function($event) {
        _vm.expanded = $event
      },
      "click:row": _vm.toggleExpandedRow
    },
    scopedSlots: _vm._u(
      [
        _vm._l(_vm.headers, function(headerItem) {
          return {
            key: "item." + headerItem.value,
            fn: function(slotProps) {
              return [_vm._t(headerItem.value, null, { props: slotProps })]
            }
          }
        }),
        {
          key: "item.data-table-expand",
          fn: function(ref) {
            var item = ref.item
            return [
              _c(
                "v-icon",
                {
                  class: {
                    "expanded-icon-active": _vm.transitioned[item.id]
                  }
                },
                [_vm._v("mdi-chevron-right")]
              )
            ]
          }
        },
        {
          key: "expanded-item",
          fn: function(ref) {
            var headers = ref.headers
            var item = ref.item
            var isMobile = ref.isMobile
            return [
              _c(
                "td",
                {
                  class: {
                    "ma-0 pa-0": true,
                    "expanded-closing": !_vm.transitioned[item.id],
                    "d-block": isMobile
                  },
                  staticStyle: { height: "auto" },
                  attrs: { colspan: headers.length }
                },
                [
                  _c("v-expand-transition", [
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.transitioned[item.id],
                            expression: "transitioned[item.id]"
                          }
                        ],
                        class: {
                          "expand-content-mobile": isMobile,
                          "expand-content": true
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "min-height": "100px" } },
                          [
                            _c(
                              "div",
                              { staticClass: "expanded-item-menu" },
                              _vm._l(_vm.expandedMenuItems, function(menuItem) {
                                return _c(
                                  "div",
                                  { key: menuItem.label },
                                  [
                                    !menuItem.hide
                                      ? _c(
                                          "v-tooltip",
                                          {
                                            attrs: { left: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    var attrs = ref.attrs
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "pa-1",
                                                              attrs: {
                                                                color: menuItem.color
                                                                  ? menuItem.color
                                                                  : "inqliPurple",
                                                                "aria-label":
                                                                  menuItem.ariaLabel
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return menuItem.action(
                                                                    item
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            "v-icon",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              menuItem.icon
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v(_vm._s(menuItem.label))
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _vm._t("expanded-row-content", null, { item: item })
                          ],
                          2
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }