<template>
  <v-data-table
    :headers="extendedHeaders"
    :items="items"
    :expanded.sync="expanded"
    item-key="id"
    show-expand
    class="elevation-1 data-table"
    @click:row="toggleExpandedRow"
    :item-class="getItemClass"
    hide-default-footer
    ref="vDataTable"
  >
    <template
      v-for="headerItem in headers"
      v-slot:[`item.${headerItem.value}`]="slotProps"
    >
      <slot :name="headerItem.value" :props="slotProps"></slot>
    </template>
    <template v-slot:item.data-table-expand="{ item }">
      <v-icon
        :class="{
          'expanded-icon-active': transitioned[item.id],
        }"
        >mdi-chevron-right</v-icon
      >
    </template>
    <template v-slot:expanded-item="{ headers, item, isMobile }">
      <td
        :colspan="headers.length"
        :class="{
          'ma-0 pa-0': true,
          'expanded-closing': !transitioned[item.id],
          'd-block': isMobile,
        }"
        style="height: auto"
      >
        <v-expand-transition>
          <div
            v-show="transitioned[item.id]"
            :class="{
              'expand-content-mobile': isMobile,
              'expand-content': true,
            }"
          >
            <div style="min-height: 100px">
              <div class="expanded-item-menu">
                <div
                  v-for="menuItem in expandedMenuItems"
                  :key="menuItem.label"
                >
                  <v-tooltip left v-if="!menuItem.hide">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        :color="menuItem.color ? menuItem.color : 'inqliPurple'"
                        @click="menuItem.action(item)"
                        class="pa-1"
                        v-bind="attrs"
                        v-on="on"
                        :aria-label="menuItem.ariaLabel"
                        >{{ menuItem.icon }}</v-icon
                      >
                    </template>
                    <span>{{ menuItem.label }}</span>
                  </v-tooltip>
                </div>
              </div>
              <slot name="expanded-row-content" :item="item"></slot>
            </div>
          </div>
        </v-expand-transition>
      </td>
    </template>
  </v-data-table>
</template>
<script>
/**
 * DataTable component
 * Display a list of item with expanded row view to show more details
 * @props {Array} items - list of items to display
 * @props {Array} headers - list of headers to display (see more on DataTable vuetify props)
 * @props {Array} expandedMenuItems - list of menu items to display in expanded row (eg. edit, delete). Attributes: label, ariaLabel, icon, action
 * @slot expanded-row-content - content of expanded row
 * @slot [headers.value] - use these slots if you want to custom content for certain columns
 * @reference https://dev.vuetifyjs.com/en/api/v-data-table/
 */
export default {
  name: "DataTable",
  data() {
    return {
      expanded: [],
      transitioned: {},
      closeTimeouts: {},
    };
  },
  computed: {
    extendedHeaders() {
      return [...this.headers, { text: "", value: "data-table-expand" }];
    },
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    expandedMenuItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggleExpandedRow(item, { expand, isExpanded }) {
      const id = item.id;
      if (isExpanded && this.transitioned[id]) {
        this.closeExpand(item);
      } else {
        this.openExpand(item, expand);
      }
    },
    openExpand(item, expand) {
      const id = item.id;
      // expand if currently closed
      clearTimeout(this.closeTimeouts[id]);
      expand(true);
      this.$nextTick(() => this.$set(this.transitioned, id, true));
      // close other expanded rows
      this.$nextTick(() =>
        this.expanded.forEach((i) => i !== item && this.closeExpand(i))
      );
    },
    closeExpand(item) {
      const id = item.id;
      // Mark that this item is in the process of closing
      this.$set(this.transitioned, id, false);
      // Remove expansion content from DOM after transition animation has had enough time to finish
      this.closeTimeouts[id] = setTimeout(
        () => this.$refs.vDataTable.expand(item, false),
        250
      );
    },
    getItemClass(item) {
      let classes = "table-row ";
      if (this.expanded.includes(item)) {
        classes += "expanded-row-active";
      }
      return classes;
    },
  },
};
</script>
<style lang="scss" scoped>
.expanded-icon-active {
  transform: rotate(90deg);
}
.expanded-item-menu {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 10px;
  cursor: pointer;
}
</style>
<style lang="scss">
.expanded-row-active {
  background-color: #eee !important;
}
.table-row {
  cursor: pointer;
}
.v-data-table__expanded__content {
  overflow: hidden;
  transition: height 200ms;
}
.expand-content {
  padding: 20px 64px;
  background-color: #fafafa;
  position: relative;
}
.expand-content-mobile {
  padding: 20px;
}
</style>
