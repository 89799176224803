var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pagination" },
    [
      _c(
        "v-btn",
        {
          staticClass: "pagination-button",
          attrs: {
            icon: "",
            small: "",
            disabled: _vm.isPrevButtonDisabled,
            "aria-label": "prev"
          },
          on: { click: _vm.onPreviousPage }
        },
        [_c("v-icon", [_vm._v(" mdi-arrow-left ")])],
        1
      ),
      _c("div", { staticClass: "current-page" }, [
        _vm._v(_vm._s(_vm.paginationMessage))
      ]),
      _c(
        "v-btn",
        {
          staticClass: "pagination-button",
          attrs: {
            icon: "",
            small: "",
            disabled: _vm.isNextButtonDisabled,
            "aria-label": "next"
          },
          on: { click: _vm.onNextPage }
        },
        [_c("v-icon", [_vm._v(" mdi-arrow-right ")])],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }